var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.filters(_vm.headers),"items":_vm.items,"search":_vm.search,"sort-by":'startTime',"sort-desc":true,"items-per-page":50,"footer-props":{
      itemsPerPageOptions: [20, 30, 50, -1],
    }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","plain":"","to":'/batch/' + item.id}},[_vm._v(" "+_vm._s(item.name.length > 20 ? ((item.name.substring(0, 20)) + "...") : item.name)+" ")])]}},{key:"item.state",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.getColor(item.state),"dark":""}},[_vm._v(" "+_vm._s({ BREWING: "Brewing", ENDED: "Finished", }[item.state])+" ")])]}},{key:"item.brewery.name",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","color":"primary","to":'/brewery/' + item.brewery.id,"dark":"","outlined":""}},[_vm._v(" "+_vm._s(item.brewery.name)+" ")])]}},{key:"item.duration",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat( (new Date(item.endTime || +new Date()) - new Date(item.startTime)) / 1000 / 3600 / 24 ).toFixed(1))+" ")]}},{key:"item.vessel.name",fn:function(ref){
    var item = ref.item;
return [(item.vessel)?_c('v-btn',{attrs:{"x-small":"","color":"primary","to":'/vessel/' + item.vessel.id,"outlined":"","dark":""}},[_vm._v(" "+_vm._s(item.vessel.name)+" ")]):_vm._e()]}},{key:"item.device.name",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","color":"purple","to":'/device/' + item.device.id,"outlined":"","dark":""}},[_vm._v(" "+_vm._s(item.device.name)+" ")])]}},{key:"item.startTime",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.startTime ? _vm.moment(new Date(item.startTime), "YYYYMMDD").format("YYYY-MMM-DD") : "---")+" ")]}},{key:"item.endTime",fn:function(ref){
    var item = ref.item;
return [(item.endTime)?_c('span',{attrs:{"title":item.endTime}},[_vm._v(" "+_vm._s(_vm.moment(new Date(item.endTime), "YYYYMMDD").format("YYYY-MMM-DD")))]):_c('v-chip',{attrs:{"small":"","color":"green","dark":""}},[_vm._v(" Brewing ")])]}},{key:"item.measurements.SG",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.measurements.find(function (x) { return x.unit.abbreviation == "SG"; }) ? parseFloat( item.measurements.find(function (x) { return x.unit.abbreviation == "SG"; }).value ).toFixed(3) : "---")+" ")]}},{key:"item.measurements",fn:function(ref){
    var item = ref.item;
return [(item.measurements.find(function (x) { return x.unit.abbreviation == 'AA'; }))?_c('v-progress-linear',{attrs:{"value":parseFloat(
            item.measurements.find(function (x) { return x.unit.abbreviation == 'AA'; })
              .value || 0
          ),"color":"purple","dark":"","rounded":"","height":"25"}},[_c('strong',[_vm._v(" "+_vm._s(Math.ceil( item.measurements.find(function (x) { return x.unit.abbreviation == "AA"; }).value ))+"%")])]):_vm._e()]}},{key:"item.measurements.ABV",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.measurements.find(function (x) { return x.unit.abbreviation == "ABV"; }) ? parseFloat( item.measurements.find(function (x) { return x.unit.abbreviation == "ABV"; }) .value ).toFixed(1) + "%" : "---")+" ")]}},{key:"item.MANUAL_SG",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.MANUAL_SG ? parseFloat(item.MANUAL_SG).toFixed(3) : "---")+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }