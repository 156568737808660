
















import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
  props: ["items"],
  data: () => ({}),
  methods: {},
})
export default class Vessels extends Vue {}
