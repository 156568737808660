<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="filters(headers)"
      :items="items"
      :search="search"
      :sort-by="'startTime'"
      :sort-desc="true"
      :items-per-page="50"
      :footer-props="{
        itemsPerPageOptions: [20, 30, 50, -1],
      }"
    >
      <template v-slot:[`item.name`]="{ item }">
        <v-btn small plain :to="'/batch/' + item.id">
          {{
            item.name.length > 20
              ? `${item.name.substring(0, 20)}...`
              : item.name
          }}
        </v-btn>
      </template>
      <template v-slot:[`item.state`]="{ item }">
        <v-chip small :color="getColor(item.state)" dark>
          {{
            {
              BREWING: "Brewing",
              ENDED: "Finished",
            }[item.state]
          }}
        </v-chip>
      </template>
      <template v-slot:[`item.brewery.name`]="{ item }">
        <v-btn
          x-small
          color="primary"
          :to="'/brewery/' + item.brewery.id"
          dark
          outlined
        >
          {{ item.brewery.name }}
        </v-btn>
      </template>
      <template v-slot:[`item.duration`]="{ item }">
        {{
          parseFloat(
            (new Date(item.endTime || +new Date()) - new Date(item.startTime)) /
              1000 /
              3600 /
              24
          ).toFixed(1)
        }}
      </template>
      <template v-slot:[`item.vessel.name`]="{ item }">
        <v-btn v-if="item.vessel"
          x-small
          color="primary"
          :to="'/vessel/' + item.vessel.id"
          outlined
          dark
        >
          {{ item.vessel.name }}
        </v-btn>
      </template>
      <template v-slot:[`item.device.name`]="{ item }">
        <v-btn
          x-small
          color="purple"
          :to="'/device/' + item.device.id"
          outlined
          dark
        >
          {{ item.device.name }}
        </v-btn>
      </template>
      <template v-slot:[`item.startTime`]="{ item }">
        {{
          item.startTime
            ? moment(new Date(item.startTime), "YYYYMMDD").format("YYYY-MMM-DD")
            : "---"
        }}
      </template>
      <template v-slot:[`item.endTime`]="{ item }">
        <span v-if="item.endTime" :title="item.endTime">
          {{
            moment(new Date(item.endTime), "YYYYMMDD").format("YYYY-MMM-DD")
          }}</span
        >
        <v-chip v-else small color="green" dark> Brewing </v-chip>
      </template>
      <!-- <template v-slot:item.measurements.LV="{ item }">
        {{
          item.measurements.find((x) => x.unit.abbreviation == "LV")
            ? parseFloat(
                item.measurements.find((x) => x.unit.abbreviation == "LV").value
              ).toFixed(0)
            : "---"
        }}
      </template> -->
      <template v-slot:[`item.measurements.SG`]="{ item }">
        {{
          item.measurements.find((x) => x.unit.abbreviation == "SG")
            ? parseFloat(
                item.measurements.find((x) => x.unit.abbreviation == "SG").value
              ).toFixed(3)
            : "---"
        }}
      </template>
      <template v-slot:[`item.measurements`]="{ item }">
        <v-progress-linear
          v-if="item.measurements.find((x) => x.unit.abbreviation == 'AA')"
          :value="
            parseFloat(
              item.measurements.find((x) => x.unit.abbreviation == 'AA')
                .value || 0
            )
          "
          color="purple"
          dark
          rounded
          height="25"
        >
          <strong>
            {{
              Math.ceil(
                item.measurements.find((x) => x.unit.abbreviation == "AA").value
              )
            }}%</strong
          >
        </v-progress-linear>
      </template>
      <template v-slot:[`item.measurements.ABV`]="{ item }">
        {{
          item.measurements.find((x) => x.unit.abbreviation == "ABV")
            ? parseFloat(
                item.measurements.find((x) => x.unit.abbreviation == "ABV")
                  .value
              ).toFixed(1) + "%"
            : "---"
        }}
      </template>
      <template v-slot:[`item.MANUAL_SG`]="{ item }">
        {{ item.MANUAL_SG ? parseFloat(item.MANUAL_SG).toFixed(3) : "---" }}
      </template></v-data-table
    >
    <!-- <pre> {{ items[0] }}</pre> -->
  </v-card>
</template>

<script lang="js">
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";

@Component({
  components: {},
  props: ["items", "hideColumns"],
  computed: {},
  data: () => ({
    search: "",
    moment,
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
        width: 100,
      },
      { text: "Started", value: "startTime" },
      { text: "Ended", value: "endTime" },
      { text: "Brewery", value: "brewery.name" },
      { text: "Vessel", value: "vessel.name" },
      { text: "Zymometer", value: "device.name" },
      // { text: "Liters", value: "measurements.LV", align: "end" },
      { text: "SG", value: "measurements.SG", align: "end" },
      // { text: "Manual SG", value: "MANUAL_SG", align: "end" },
      {
        text: "Apparent Attenuation",
        value: "measurements",
        align: "end",
        sort: (a, b) =>
          (a.find((x) => x.unit.abbreviation == "AA") || {}).value -
          (b.find((x) => x.unit.abbreviation == "AA") || {}).value,
      },
      // { text: "ABV", value: "measurements.ABV", align: "end" },
      { text: "Duration Days", value: "durationDays", align: "end" },
      // { text: "Status", value: "state" },
    ],
  }),
  methods: {
    getColor(status) {
      if (status == "ENDED") return "green";
      else if (status == "ERROR") return "red";
      else return "blue";
    },
    filters(headers) {
      if (this.hideColumns)
        headers = headers.filter((x) => this.hideColumns.indexOf( x["value"])<0);
      return headers;
    },
  },
})
export default class Breweries extends Vue {}
</script>
