



























import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
  computed: {
    assets() {
      return this.$store.state.assets;
    },
  },
  data: () => ({
    selected: null,
  }),
  methods: {},
})
export default class Breweries extends Vue {}
